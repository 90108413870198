<template>
  <div>
    <headerTop />
    <breadcrumb :breadList="breadList"/>
    <div class="n-content">
      <div class="desc">
        <span v-html="this.$route.params.content"></span>
      </div>
    </div>
    <div style="height: 100px;"></div>
    <footerBottom />
  </div>
</template>
<script>
import headerTop from "../../components/header.vue";
import breadcrumb from "../../components/breadcrumb.vue";
import footerBottom from "../../components/footer.vue";
export default {
  components: {
    headerTop,
    breadcrumb,
    footerBottom,
  },
  data() {
    return {
        breadList:  ["Home", "News", `${this.$route.params.label}`],
    };
  },
  mounted() {
    
  },
  methods: {},
};
</script>
<style scoped>
.header-img {
  width: 100%;
  height: 500px;
}
.header-img img {
  width: 100%;
  height: 100%;
}
.n-content {
  padding: 0 166px;
  box-sizing: border-box;
  margin-top: 50px;
}
.n-content .title {
  font-size: 30px;
  color: #333333;
  margin-bottom: 30px;
}
.n-content .desc {
  font-size: 16px;
  color: #333333;
}
.bed-img {
  margin-top: 30px;
  margin-bottom: 50px;
  height: 600px;
}
.bed-img img {
  width: 100%;
  height: 100%;
}
</style>
