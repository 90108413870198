<template>
  <div class="home">
      <div class="header">
          <div class="bacc">

              <div class="header-top">
                  <div class="logo" @click="handleLogo">
                      <img src="../../assets/home/logo1.png" />
                  </div>
                  <ul class="menuBox">
                      <li :class="'home' == item.names ? 'menu-item active' : 'menu-item'" v-for="item in menuList" :key="item.id"
                          @click.stop="handleMenu(item)"
                          @mouseover.stop="routerShow(item)" @mouseleave="handleMouseLeave(item)">
                          <span v-html="item.name"></span>
                          <svg v-show="item.icon" style="margin-left: 5px" t="1715135267702" class="icon" viewBox="0 0 1024 1024"
                               version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1451" width="16" height="16">
                              <path
                                      d="M512.726547 675.318646c-8.063653 0-15.790638-3.245927-21.435195-9.006118L231.175103 400.906809c-11.603269-11.837606-11.410887-30.840402 0.427742-42.442648 11.837606-11.601222 30.841426-11.410887 42.442648 0.427742l238.681054 243.534596L751.407602 358.891903c11.601222-11.839653 30.602995-12.033058 42.442648-0.427742 11.839653 11.603269 12.031011 30.605042 0.427742 42.442648L534.161742 666.312528C528.517185 672.072719 520.791224 675.318646 512.726547 675.318646z"
                                      fill="#ffffff" p-id="1452"></path>
                          </svg>
                          <div v-show="item.IschildShow" class="childmenu">
                              <div class="s-our-serive-content">
                                  <div>
                                      <div>
                                          <img class="s-our-serive-content-item-img" style="vertical-align: center" src="../../assets/serivice/icon_2.png">
                                          <span style="color: #333333">Home & Condo Inspection List</span>
                                      </div>
                                      <div class="header-home-list-child">
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/structural'})">Structural</span>
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/architectural'})">Architectural</span>
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/landscaping'})">Landscaping</span>
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/electrical'})">M&E Electrical</span>
                                      </div>
                                  </div>
                                  <div>
                                      <div>
                                          <img class="s-our-serive-content-item-img" style="vertical-align: center"
                                               src="../../assets/serivice/icon_1.png">
                                          <span style="color: #333333">Service</span>
                                      </div>
                                      <div class="header-home-list-child">
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/homeAppliances'})">Home Appliances</span>
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/electrical'})">Elecrical Appliances</span>
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/swimming'})">Swimming Pool</span>
                                      </div>
                                  </div>
                                  <div>
                                      <div>
                                          <img class="s-our-serive-content-item-img" style="vertical-align: center"
                                               src="../../assets/serivice/icon_6.png">
                                          <span style="color: #333333">Consultation</span>
                                      </div>
                                      <div class="header-home-list-child">
                                          <span @click.stop="handleChildMenu(item, {path:'/serivices/consultation'})">Provide consultation on home inspections or construction work</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </li>
                      <div class="bookinspection" style="margin-right: 30px;">
                          <p class="bookBtn" @click="handleShowComment">BOOK INSPECTION</p>
                      </div>
                  </ul>
              </div>
          </div>
          <div class="header-bottom">
              <p class="font" style="font-size: 50px; color: white;margin-bottom: 20px">Pre-delivery Inspection (PDI) </p>
              <p class="frist font" style="margin-bottom: 40px;">Service and Home Service</p>
              <p class="second ">Provides customers who own buildings, residential houses, condominiums, and entrepreneurs with the purpose of providing customers with confidence in receiving the building from the owner and contractors. The team is responsible for building inspection and maintenance services for the safety of the building. The Samui home service will be the assistant to maintain the building, repair, home and other equipment as a home's own.</p>
          </div>
      </div>
    <div style="height: 200px; background-color: #007562;display: flex; ">
      <img style="width: 1272px; height: 138px; margin:  auto" src="../../assets/home/iocn_1.png" alt="">
    </div>
    <div class="door_box" style="margin-bottom: 40px;">
      <p class="font"
        style="margin: 20px auto; margin-top: 30px; width: 100%; text-align: center; font-weight: bold; color: #007562;font-size: 30px;">
        Door</p>
      <p
        style="font-weight: 400;font-size: 18px;line-height: 48px;width: 100%;text-align: center; color: #333333; margin: 20px 0 40px 0;">
        Inspect the operation of the automatic door system.</p>
      <div style="display: flex; flex-direction: row;justify-content: center;">
        <div>
          <div style="position: relative;margin: 0 20px;">
            <img style="height: 340px; width: 285px; " src="../../assets/home/img1.png" alt="">
            <div
              style="width: 100%;text-align: center;font-size: 18px;color: #FFFFFF; position: absolute;top: 90%;left: 50%;transform: translate(-50%, -50%); ">
              Electronic Combination Locks
            </div>
          </div>
        </div>
        <div>
          <div style="position: relative;margin: 0 20px;">
            <img style="height: 340px; width: 285px; " src="../../assets/home/img2.png" alt="">
            <div
              style="width: 100%;text-align: center;font-size: 18px;color: #FFFFFF; position: absolute;top: 90%;left: 50%;transform: translate(-50%, -50%); ">
              Electronic Combination Locks
            </div>
          </div>
        </div>
        <div>
          <div style="position: relative;margin: 0 20px;">
            <img style="height: 340px; width: 285px; " src="../../assets/home/img3.png" alt="">
            <div
              style="width: 100%;text-align: center;font-size: 18px;color: #FFFFFF; position: absolute;top: 90%;left: 50%;transform: translate(-50%, -50%); ">
              Electronic Combination Locks
            </div>
          </div>
        </div>
        <div>
          <div style="position: relative;margin: 0 20px;">
            <img style="height: 340px; width: 285px; " src="../../assets/home/img4.png" alt="">
            <div
              style="width: 100%;text-align: center;font-size: 18px;color: #FFFFFF; position: absolute;top: 90%;left: 50%;transform: translate(-50%, -50%); ">
              Electronic Combination Locks
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="hood_box">
      <p class="font"
        style="margin: 20px auto; width: 100%; text-align: center; font-weight: bold;font-size: 30px;color: #007562;">
        Range Hood and Exhaust Fan</p>
      <p
        style="font-weight: 400;font-size: 18px;color: #333333;line-height: 48px;width: 100%;text-align: center; margin: 20px 0 40px 0;">
        Water pump systems, cleaning of water tanks and grease traps, repair, and replacement of sanitary equipment.</p>
      <div style="display: flex;flex-direction: row; justify-content: space-between;width: 100%;">
        <div style="width: 16%;"><img style=" width: 100%; border-radius: 50px  0px  50px  0px; "
            src="../../assets/home/hood.png" alt=""></div>
        <div style="width: 16%;"><img style=" width: 100%; border-radius: 50px  0px  50px  0px; "
            src="../../assets/home/hood1.png" alt=""></div>
        <div style="width: 16%;"><img style=" width: 100%; border-radius: 50px  0px  50px  0px; "
            src="../../assets/home/hood2.png" alt=""></div>
        <div style="width: 16%;"><img style=" width: 100%; border-radius: 50px  0px  50px  0px; "
            src="../../assets/home/hood3.png" alt=""></div>
        <div style="width: 16%;"><img style=" width: 100%; border-radius: 50px  0px  50px  0px; "
            src="../../assets/home/hood4.png" alt=""></div>
      </div>
    </div>
    <div class="hood_box">
      <p class="font"
        style="margin: 30px auto; width: 100%; text-align: center; font-weight: bold;color: #007562;font-size: 30px;">
        Air Conditioner</p>
      <p style="font-weight: 400;font-size: 18px;color: #333333;width: 100%;text-align: center;line-height: 40px; ">
        Inspect the operation of the automatic door system.</p>
      <p style="font-weight: 400;font-size: 18px;color: #333333;width: 100%;text-align: center;line-height: 40px; ">
        Repair, maintenance, cleaning, replacement of equipment, or reinstallation.</p>
      <p style="font-weight: 400;font-size: 18px;color: #333333;width: 100%;text-align: center;line-height: 40px; margin-bottom: 20px;">
        Comprehensive air conditioning cleaning, sales, installation, repair, or relocation.</p>

      <div style="display: flex; flex-direction: row; justify-content: center;">
        <div style="margin: 30px 20px;"><img style="width: 422px;height: 233px;"
            src="../../assets/home/air_conditioning.png" alt=""></div>
        <div style="margin: 30px 20px;"><img style="width: 422px;height: 233px;"
            src="../../assets/home/air_conditioning1.png" alt=""></div>
        <div style="margin: 30px 20px;"><img style="width: 422px;height: 233px;"
            src="../../assets/home/air_conditioning2.png" alt=""></div>
      </div>

    </div>
    <div>
      <div style="display: flex;flex-direction: row;">
        <div style="width: 50%; height: 400px; display: flex; background-color: #007562">
          <div style="width: 326px; height: 205px; margin: auto;">
            <div style="display: flex; flex-direction: row; align-items: center;"><img
                style="height: 55px; width: 55px;  margin-right: 20px" src="../../assets/home/icon_Bathroom.png" alt="">
              <p style="line-height: 47px;font-size: 30px; color: #FFFFFF;font-weight: 600;">Bathroom</p>
            </div>
            <p style="margin-top: 50px; font-size: 20px; line-height: 32px; color: #FFFFFF;">Repair, replacement, and installation of water heaters or
              electric water heaters.</p>
          </div>
        </div>
        <div style="width: 50%; height: 400px;"><img style="height: 100%; width: 100%;"
            src="../../assets/home/closestool.png" alt=""></div>
      </div>
      <div style="display: flex;flex-direction: row;">
        <div style="width: 50%; height: 400px;"><img style="height: 100%; width: 100%;"
            src="../../assets/home/electric_brake.png" alt=""></div>

        <div style="width: 50%; height: 400px; display: flex; background-color: #007562">
          <div style="width: 326px; height: 205px;  margin: auto;">
            <div style="display: flex; flex-direction: row; align-items: center;"><img
                style="height: 55px; width: 55px;font-size: 30px;  margin-right: 20px"
                src="../../assets/home/icon_Electricity.png" alt="">
              <p style="font-size: 30px; line-height: 47px;color: #FFFFFF;font-weight: 600;">Electricity</p>
            </div>
            <p style="margin-top: 50px; font-size: 20px; line-height: 32px; color: #FFFFFF;">Repair, replacement of circuit breakers,
              or installation of electrical appliances and light fixtures.</p>
          </div>
        </div>

      </div>
    </div>
    <div class="content">
      <div class="third font" style="display: flex; flex-direction: row; margin-left: 20px;"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Certification
      </div>
      <p>
      <p><b>1.</b>Electrical System: The measured current values are correct. Connection points do not show excessive
        heat. The
        operation of plugs and switches is normal. All breaker labels are correctly placed on the electrical control
        panel.</p>

      <p><b>2.</b>Air Conditioning System: The refrigerant levels and operational current are normal. Cooling
        efficiency, tested
        with a thermo-scan device, is within acceptable limits. It is recommended to clean the air conditioner every 6
        months.</p>

      <p><b>3.</b>Internet and Wi-Fi System: Operational and functioning normally.</p>

      <p><b>4.</b>Plumbing System: The operation of the water pump and piping system has been tested, with no
        abnormalities found.
        Hot water and water heater systems have been checked for current and grounding, with no leakage current
        detected. All systems are functioning normally. Drainage systems are also normal. It is recommended to clean the
        drain covers every time the bathroom is used to maintain optimal operation.</p>

      <p><b>5.</b>Grounding System: Checked with a measuring device and found to be operating within standard values.
      </p>

      <p><b>6.</b>Automatic Electric Door System: Current and operational tests indicate normal functioning.</p>

      <p><b>7.</b>Solar Cell System: Operating normally. The solar panels are clean. It is recommended to clean the
        solar panels
        at least every 6 months for optimal performance.</p>

      <p><b>8.</b>Digital Door Lock System: Operating normally. It is recommended to change the batteries every 6
        months.</p>

      <p><b>9.</b>Gas System: The safety valve installation is correct, and the system is functioning normally. It is
        recommended
        to close the valve on the gas tank before closing the stove valve after each use.</p>

      <p><b>10.</b>Ventilation System: Operating normally. It is recommended to pull the cord to close the outer vent
        cover in the
        dressing room every time the fan is not in use.</p>

      </p>
    </div>
    <commentDialog :show.sync="show" />
    <footer-bottom />
  </div>
</template>
<script>
import { RouterLink } from "vue-router";
import footerBottom from "../../components/footer.vue";
import { getNewsList, getProtfolioList } from "@/api/contact";
import commentDialog from "./component/commentDialog.vue"
export default {
  components: {
    footerBottom,
    commentDialog
  },
  data() {
    return {
      show: false,
      products: [],
      news: [],
        menuList: [
            {
                id: "1",
                names: 'home',
                name: "Home",
                path: "/",
            },
            {
                id: "2",
                names: 'index',
                name: "About Us",
                path: "/index",
            },
            {
                id: "3",
                names: "services",
                name: "Home Delivery Service <br/> & Inspection",
                icon: true,
                IschildShow: false,
                path: "/serivices",
                child: [
                    {
                        id: "1",
                        imgSrc: require("../../assets/serivice/icon_1.png"),
                        content: "Hood Inspection",
                        desc: "range hood, ventilation fans and cleanliness",
                        path: "/serivices/hood",
                    },
                    {
                        id: "2",
                        imgSrc: require("../../assets/serivice/icon_2.png"),
                        content: "Door and Window Inspection",
                        desc: "automatic door system and digital door lock",
                        path: "/serivices/door",
                    },
                    {
                        id: "3",
                        imgSrc: require("../../assets/serivice/icon_3.png"),
                        content: "Electrical Inspection",
                        desc: "electrical inspection interior and exterior",
                        path: "/serivices/electrical",
                    },
                    {
                        id: "4",
                        imgSrc: require("../../assets/serivice/icon_4.png"),
                        content: "Structural Inspection",
                        desc: "foundation, structure and inside wall inspection ",
                        path: "/serivices/structural",
                    },
                    {
                        id: "5",
                        imgSrc: require("../../assets/serivice/icon_4.png"),
                        content: "Internet and CCTV Inspection",
                        desc: "internet system and CCTV system",
                        path: "/serivices/internet",
                    },
                    {
                        id: "6",
                        imgSrc: require("../../assets/serivice/icon_6.png"),
                        content: "Roof Inspection",
                        desc: "roof and rain gutters",
                        path: "/serivices/roof",
                    },
                    {
                        id: "7",
                        imgSrc: require("../../assets/serivice/icon_7.png"),
                        content: "Plumbing Inspection",
                        desc:
                            "water meters, water storage tanks, water pumps, sanitary ware, wastewater treatment tanks, grease traps, and drainage systems",
                        path: "/serivices/plumbing",
                    },
                    {
                        id: "8",
                        imgSrc: require("../../assets/serivice/icon_8.png"),
                        content: "Air conditioning Inspection",
                        desc: "foundation, structure and inside wall inspection ",
                        path: "/serivices/air",
                    },
                    {
                        id: "9",
                        imgSrc: require("../../assets/serivice/icon_9.png"),
                        content: "Stair Inspection",
                        desc: "stair for public area",
                        path: "/serivices/stair",
                    },
                ],
            },
            {
                id: "4",
                names: "portfolio",
                name: "Portfolio",
                path: "/portfolio",
            },
            {
                id: "5",
                names: "reviews",
                name: "Reviews",
                path: "/reviews",
            },
            {
                id: "6",
                names: "contact",
                name: "Contact Us",
                path: "/contact",
            },
        ],
    };
  },
  mounted() {
    const _this = this;
    document.addEventListener('click', function () {
      _this.menuList[1].IschildShow = false
    }, false);

    // 产品图
    getProtfolioList(1, 8).then(res => {
      if (res.code === 200) {
        this.products = res.rows
      }
    })

    // 新闻
    getNewsList(1, 4).then(res => {
      if (res.code === 200) {
        this.news = res.rows;
      }
    })
  },
  methods: {
    handleMouseLeave(item){
      if(item.id == 3){
        item.IschildShow = false;
      }
    },
    routerShow(item){
      if (item.id != 3) {
        item.IschildShow = false;
        // this.$router.push(
        //   item.path,
        //   () => { },
        //   () => { }
        // );
      } else {
        item.IschildShow = true;
      }
    },
    handleShowComment() {
      this.show = true
      console.log(this.show, '123123');
    },
    newsRouter() {
      this.$router.push({ name: "new" })
    },
    reviewsRouter() {
      this.$route.push({ name: "reviews" })
    },
    PortfolioRouter() {
      this.$router.push({ name: "portfolio" })
    },
    handleLogo() {
      this.$router.push({ name: 'home' })
    },
    handlegoDetail(item) {
      this.$router.push({ name: 'newDetail', params: item })
    },
    handleMenu(item) {
      if (item.id != 3) {
        item.IschildShow = false;
        this.$router.push(
          item.path,
          () => { },
          () => { }
        );
      } else {
        item.IschildShow = true;
      }
    },
    RouterLink() {
      this.$router.push({ name: 'certicication' })
    },
    handleChildMenu(item, child) {
      item.IschildShow = false;
      this.$router.push(
        child.path,
        () => { },
        () => { }
      );
    },
  },
};
</script>
<style scoped>
.content {
  padding: 0 300px;
  box-sizing: border-box;
  margin: 20px 0 100px 0;
}

.content p {
  font-size: 20px;


  color: #333333;
  line-height: 40px;
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font {
  font-family: 'MyCustomFont', sans-serif;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;
  background: #b7aea1;
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
  justify-content: space-between;
  background: url("../../assets/home/编组4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.header-top {
  display: flex;
  width: 100%;
  height: 1080px;
  justify-content: space-between;
}

.logo {
  cursor: pointer;
  width: 70px;
  height: 50px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.menuBox {
  display: flex;
  width: 85%;
  justify-content: space-between;
  color: #fff;
  height: 50px;
  align-items: center;
  font-size: 16px;
}

.menuBox .menu-item {
  cursor: pointer;
  position: relative;
}

.bookBtn {
  width: 200px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  color: #007562;
  font-weight: 800;
  cursor: pointer;
  margin-left: 10px;
}

.header-bottom {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-bottom .frist {
  font-size: 30px;
  text-align: center;
  color: #fff;
}

.header-bottom .second {
  font-size: 18px;
  text-align: center;
  color: #fff;
  opacity: 0.73;
  margin-top: 28px;
}

.intention {
  width: 100%;
  height: 700px;
  background: #fff;
  padding: 100px 166px 79px;
  box-sizing: border-box;
}

.intention .frist {
  color: #007562;
  text-align: center;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.intention .sceoned {
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
}

.third {
  color: #007562;
  text-align: center;
  font-size: 30px;
  margin-top: 100px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.offer-box {
  width: 100%;
  height: 730px;
  background: #007562;
  padding: 50px 90px 100px;
  box-sizing: border-box;
}

.third-box {
  display: flex;
  justify-content: space-between;
}

.offer-box .frist {
  font-size: 30px;
  text-align: center;
  color: #fff;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  text-indent: 100px;
}

.offer-box .sconed {
  font-size: 30px;
  text-align: center;
  color: #fff;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  text-indent: 100px;
}

.offer-box .third {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offer-box .third li {
  text-align: center;
  margin: 0 auto;
}

.offer-box .third li img {
  width: 100%;
  max-width: 380px;
  max-height: 400px;
  object-fit: cover;
  /*width: 530px;*/
  /*height: 640px;*/
}

.offer-box .third li p {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 21px;
}

.work-box {
  padding: 100px 166px 79px;
  box-sizing: border-box;
}

.work-box .frist {
  font-size: 30px;
  color: #007562;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.work-box .sceoned {
  margin-top: 26px;
  text-align: center;
}

.products-box {
  padding: 0 167px 100px;
}

.products-box .frist {
  font-size: 30px;
  color: #007562;
  text-align: center;
  margin-top: 129px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.products-box .sceoned {
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
}

.products-box .more {
  display: flex;
  justify-content: end;
  width: 100%;
}

.products-box .moreBtn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #007562;
  text-align: center;
  line-height: 30px;
  color: #007562;
  font-size: 16px;
  margin-right: 31px;
  margin-top: 10px;
}

.products-img-list {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.products-img-list li {
  width: 24.4%;
  margin-right: 9px;
  font-size: 18px;
}

.products-img-list li img {
  width: 100%;
}

.products-img-list li p {
  text-align: center;
  color: #333333;
  margin: 24px 0;
}

.products-box .third {
  margin-top: 101px;
  font-size: 30px;
  color: #007562;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.reviews {
  width: 100%;
  display: flex;
  justify-content: end;
}

.reviewsBtn {
  width: 190px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #007562;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #007562;
  margin-top: 20px;
}

.reviews-img-list {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.reviews-img-list li {
  width: 33%;
  height: 304px;
  box-shadow: 1px 6px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px 32px 29px 34px;
  box-sizing: border-box;
}

.avtar-box {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.avtar-box .name {
  font-size: 28px;
  color: #007562;
  margin-left: 13px;
  vertical-align: middle;
}

.reviews-desc {
  /* margin: 20px 32px 29px 34px; */
  margin-top: 20px;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  background: #f3f8f8;
  height: 180px;
  font-size: 16px;
}

.reviews-desc p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.reviews-desc .reviews-more {
  color: #007562;
  font-size: 14px;
}

.insights-box {
  width: 100%;
  height: 738px;
  background: #f4f4f4;
  padding: 60px 166px 100px;
  box-sizing: border-box;
}

.insights-box .frist {
  text-align: center;
  font-size: 30px;
  color: #007562;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.insights-box .second {
  text-align: center;
  font-size: 18px;
  color: #333333;
  margin-top: 30px;
}

.insights-box .explore {
  display: flex;
  justify-content: end;
  width: 100%;
}

.insights-box .exploreBtn {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #007562;
  text-align: center;
  line-height: 30px;
  color: #007562;
  font-size: 16px;
  margin-top: 10px;
}

.insights-box-list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.insights-box-list li {
  position: relative;
  width: 32%;
  height: 353px;
  background: #fff;
  border-radius: 10px;
}

.insights-box-list li img {
  height: 302px;
}

.box-list-label {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #FFFFFF;
  padding: 4px;
  border-radius: 3px;
}

.insights-img-box {
  height: 225px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.insights-desc {
  margin-top: 17px;
  margin-left: 20px;
  font-size: 16px;
}

.insights-more {
  margin-top: 25px;
  margin-left: 20px;
  font-size: 16px;
  color: #007562;
  text-decoration: underline;
  cursor: pointer;
}

.childmenu {
  width: 738px;
  height: 460px;
  position: absolute;
  top: 20px;
  left: -300px;
}

.s-our-serive-content {
  height: 430px;
  background: #f3f8f8;
  border-radius: 10px;
  border: 1px solid #007562;
  margin-bottom: 50px;
  padding: 52px 2px 50px 28px;
  box-sizing: border-box;
}

.s-our-serive-content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.s-our-serive-content-item {
  width: 48%;
  display: flex;
  margin-bottom: 20px;
  /* margin-bottom: 101px; */
}

.s-our-serive-content-item-img {
  width: 22px;
  height: 22px;
  background: #007562;
  margin-right: 10px;
  margin-top: 7px;
}

.s-our-serive-content-item-img img {
  width: 100%;
  height: 100%;
}

.s-our-serive-content-item-content {
  font-size: 16px !important;
  color: #000000;
}

.s-our-serive-content-item-desc {
  font-size: 12px;
  color: #333333;
  margin-top: 2px;
}


.header-home-list-child {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.header-home-list-child span{
    cursor: pointer;
    color: #999999;
    margin-right: 30px;
}

.header-home-list-child span:hover {
    color: #007562;
}
</style>
