var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerTop'),_c('breadcrumb',{attrs:{"breadList":_vm.breadList}}),_c('div',{staticClass:"h-title-box"},[_vm._m(0),_c('p',{staticClass:"h-detail"},[_vm._v(" The objective of Samui Home Service is to deliver exceptional services to condominium and residential building owners, ensuring their safety and comfort within their premises. We strive to act as a trusted butler, attending to their cleaning and repair needs, just as one would care for their own home. ")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',{staticClass:"h-detail"},[_vm._v(" At Samui Home Service, association with GainGood home service, we are inspired by innovation, powered by the communities we serve, and committed to achieving excellence before, during and after the inspection. As a national network of highly trained, certified and professional home inspectors, we live by our core values and utilize them to drive virtually every aspect of our business so that we stay focused on what's most important delivering quality home inspection services to you, our clients. ")]),_c('ul',{staticClass:"h-img-list"},_vm._l((_vm.list),function(item){return _c('li',{key:item.id,staticClass:"h-img-list-item"},[_c('p',[_c('img',{attrs:{"src":item.imgSrc,"alt":""}})]),_c('p',{staticClass:"title font"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(item.content))])])}),0)]),_vm._m(4),_c('div',{staticClass:"h-certification"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"c-contact-box"},[_vm._m(7),_c('div',{staticClass:"c-contact-map"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":20,"map-type-id":"terrain"}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = m.position}}})}),1)],1)])]),_c('footerBottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h-title font"},[_c('span',{staticStyle:{"height":"45px","width":"10px","border-radius":"0px 100px 100px 0px","background-color":"#007562","display":"block"}}),_vm._v("Our Objective")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h-title font"},[_c('span',{staticStyle:{"height":"45px","width":"10px","border-radius":"0px 100px 100px 0px","background-color":"#007562","display":"block"}}),_vm._v("How we work")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h-detail"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/index/AboutUs-1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h-title font"},[_c('span',{staticStyle:{"height":"45px","width":"10px","border-radius":"0px 100px 100px 0px","background-color":"#007562","display":"block"}}),_vm._v("Our Core Values That Drive Our Mission")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-c-img-box"},[_c('p',{staticClass:"title font"},[_c('span',{staticStyle:{"height":"45px","width":"10px","border-radius":"0px 100px 100px 0px","background-color":"#007562","display":"block"}}),_vm._v("Our Team")]),_c('div',{staticClass:"h-c-detail-box"},[_c('p',{staticClass:"desc"},[_vm._v(" Service remains at the forefront of everything that we do, and that value is emphasized through our client-centric approach, support for veterans, and community focus. We treat every home as if it were our own and go out of our way to ensure our clients are well-informed, safe, and healthy.With every home inspection we conduct, we are not just providing an essential service, but serving as a compassionate partner, vested in the well-being of our clients. ")]),_c('p',{staticClass:"videoImg"},[_c('iframe',{attrs:{"width":"500","height":"300","src":"https://www.youtube.com/embed/WOjhFNe5-4c?cc_lang_pref=en","title":"GianGood Service","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","referrerpolicy":"strict-origin-when-cross-origin","allowfullscreen":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('img',{staticStyle:{"height":"100%"},attrs:{"src":require("../../assets/home/bacc.png"),"alt":""}}),_c('div',{staticClass:"h-certification-content"},[_c('div',[_c('p',{staticClass:"h-certification-content-detail font",staticStyle:{"font-size":"40px","line-height":"50px","margin-bottom":"20px"}},[_vm._v("Our team consists of: ")]),_c('p',{staticClass:"h-certification-content-detail"},[_vm._v("Senior Civil Engineers,")]),_c('p',{staticClass:"h-certification-content-detail"},[_vm._v("Professional Civil Engineer, ")]),_c('p',{staticClass:"h-certification-content-detail"},[_vm._v(" Professional environmental Engineer,  ")]),_c('p',{staticClass:"h-certification-content-detail"},[_vm._v(" Professional Electrical Engineers, ")]),_c('p',{staticClass:"h-certification-content-detail"},[_vm._v(" Professional Mechanical Engineers, ")]),_c('p',{staticClass:"h-certification-content-detail"},[_vm._v(" Professional Architect and building inspectors. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"c-title font"},[_c('span',{staticStyle:{"height":"45px","width":"10px","border-radius":"0px 100px 100px 0px","background-color":"#007562","display":"block"}}),_vm._v("Contact us")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-contact-text"},[_c('div',{staticClass:"title",staticStyle:{"padding-left":"40px"}},[_c('p',{staticClass:"p1 font",staticStyle:{"color":"#007562"}},[_vm._v("Samui Home Service")])]),_c('ul',{staticClass:"c-contact-message"},[_c('li',{staticClass:"c-contact-message-item"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/index/icon_phone.png"),"alt":""}})]),_c('div',{staticClass:"c-contact-message-address"},[_c('p',{staticClass:"c-contact-message-frist font"},[_vm._v("Mobile phone")]),_c('p',{staticClass:"c-contact-message-second"},[_vm._v("+66 0935748046")])])]),_c('li',{staticClass:"c-contact-message-item"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/index/icon_email.png"),"alt":""}})]),_c('div',{staticClass:"c-contact-message-address"},[_c('p',{staticClass:"c-contact-message-frist font"},[_vm._v("Email")]),_c('p',{staticClass:"c-contact-message-second"},[_vm._v("steven@samuihomeservice.com")])])]),_c('li',{staticClass:"c-contact-message-item"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/index/icon_facebook.png"),"alt":""}})]),_c('div',{staticClass:"c-contact-message-address"},[_c('p',{staticClass:"c-contact-message-frist font"},[_vm._v("Facebook")]),_c('p',{staticClass:"c-contact-message-second"},[_vm._v("@SamuiHomeService")])])]),_c('li',{staticClass:"c-contact-message-item-boom"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/index/icon_address.png"),"alt":""}})]),_c('div',{staticClass:"c-contact-message-address"},[_c('p',{staticClass:"c-contact-message-frist font"},[_vm._v("Address")]),_c('p',{staticClass:"c-contact-message-second"},[_vm._v("Samui Town Center, 108/13 Moo 1, Bophut, Samui, Amphoe Koh SaMui, Surat Thani, Thailand 84320.")])])])])])
}]

export { render, staticRenderFns }