import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/reset.css'
import ElementUI from 'element-ui';
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import * as VueGoogleMaps from 'vue2-google-maps'
// import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(ElementUI, {elementEnLocale});
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBG7V3NzTXV3yPjJbxZF-bMRVkAF3U_Mh4',
    libraries: 'places',
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

