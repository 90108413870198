<template>
  <div class="serivices">
    <div class="mask" v-if="show" @click.stop="hide">
      <!-- 遮罩层的内容 -->
      <div class="pop-content" @click.stop="">
        <div class="pop-header">
          <img style="height: 80px; width: 80px;margin: 50px 0 20px 0; text-align: center;"
            src="../../assets/home/icon_House.png" alt="">
          <p style="font-weight: 800;" class="font">Requesting an inspection has</p>

          <p style="font-weight: 800;" class="font">never been easier!</p>
        </div>

        <div class="pop-form" style="z-index: 9999 !important;">
          <el-form ref="form" :model="form" label-width="80px">
            <!-- <el-checkbox-group v-model="form.type" :max="4">
              <el-checkbox label="Samui"></el-checkbox>
              <el-checkbox label="Bangkok"></el-checkbox>
              <el-checkbox label="Phuket"></el-checkbox>
              <el-checkbox label="Pattaya"></el-checkbox>
            </el-checkbox-group> -->

            <el-input class="input" v-model="form.name" placeholder="Name"></el-input>

            <el-input class="input" v-model="form.email" placeholder="Email"></el-input>

            <el-input class="input" v-model="form.telephone" placeholder="Telephone"></el-input>
            <el-button class="submitBtn" type="primary" @click="onSubmit">BOOK NOW</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bookInspection } from "@/api/contact";

export default {
  name: "",
  props: ["show"],
  data() {
    return {
      form: {
        type: [],
        serverName: "",
        name: "",
        telephone: "",
        email: ""
      },
    };
  },

  methods: {
    onSubmit() {
      this.form.serverName = this.form.type.toString();
      bookInspection(this.form).then(res => {
        this.hide()
      })
    },
    hide() {
      this.$emit('update:show', !this.show)
    }
  },
};
</script>
<style scoped>
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.font{
  font-family: 'MyCustomFont', sans-serif;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}

.pop-content {
  width: 600px;
  height: 680px;
  background-color: #fff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

}

.pop-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #007562;
  /* background-color: #007562; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* height: 130px; */
  font-size: 20px;
  box-sizing: border-box;
  text-align: center;
  text-wrap: wrap;

}

.pop-form {
  padding: 50px 113px;
  box-sizing: border-box;

}

.input {
  margin-bottom: 30px;
  height: 62px;
}

/deep/.el-input__inner {
  height: 62px;

}

/deep/.el-input__inner:hover {
  border-color: #007562
}


.submitBtn {
  width: 100%;
  height: 65px;
  background: #007562;
  border: 1px solid green;
}

.submitBtn:hover {
  background: #007562
}

.el-button--primary:focus,
.el-button--primary:hover {
  /* background: green !important; */
  border: 1px solid #007562;
}

.el-checkbox-group {
  display: flex;
  flex-wrap: nowrap;
  /* justify-content: space-between; */
}

.el-checkbox {
  width: 34%;
  margin-right: 0;
  margin-bottom: 30px;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #000 !important;
}






/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #007562;
  border: #007562;
}

/deep/.el-checkbox:hover .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #007562;
  border-color: #007562;
}

/deep/.el-checkbox:hover .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #007562;
  border-color: #007562;
}
</style>
