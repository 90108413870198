<template>
  <div class="header">
    <div class="header-top">
      <div class="logo" @click="handleLogo">
        <img src="../assets/home/logo.png" alt="" />
      </div>
      <ul class="menuBox">
        <li class="menu-item" :class="$route.name == item.names ? 'active' : ''" v-for="item in menuList" :key="item.id"
          @click.stop="handleMenu(item) "@mouseover.stop="routerShow(item)"@mouseleave="handleMouseLeave(item)">
            <span v-html="item.name"></span>
          <svg v-show="item.icon" style="margin-left: 5px" t="1715124426170" class="icon" viewBox="0 0 1024 1024"
            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1459" width="16" height="16">
            <path
              d="M512 682.666667c-4.266667 0-8.533333 0-12.8-4.266667l-362.666667-298.666667c-8.533333-8.533333-12.8-21.333333-4.266666-29.866666 8.533333-8.533333 21.333333-12.8 29.866666-4.266667l349.866667 285.866667 349.866667-285.866667c8.533333-8.533333 21.333333-4.266667 29.866666 4.266667 8.533333 8.533333 4.266667 21.333333-4.266666 29.866666l-362.666667 298.666667c-4.266667 4.266667-8.533333 4.266667-12.8 4.266667z"
              fill="" p-id="1460"></path>
          </svg>
          <div v-show="item.IschildShow" class="childmenu">
            <div class="s-our-serive-content">
                <div>
                    <div>
                        <img class="s-our-serive-content-item-img" style="vertical-align: center" src="../assets/serivice/icon_2.png">
                        <span>Home & Condo Inspection List</span>
                    </div>
                    <div class="header-home-list-child">
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/structural'})">Structural</span>
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/architectural'})">Architectural</span>
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/landscaping'})">Landscaping</span>
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/electrical'})">M&E Electrical</span>
                    </div>
                </div>
                <div>
                    <div>
                        <img class="s-our-serive-content-item-img" style="vertical-align: center"
                             src="../assets/serivice/icon_1.png">
                        <span>Service</span>
                    </div>
                    <div class="header-home-list-child">
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/homeAppliances'})">Home Appliances</span>
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/electrical'})">Elecrical Appliances</span>
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/swimming'})">Swimming Pool</span>
                    </div>
                </div>
                <div>
                    <div>
                        <img class="s-our-serive-content-item-img" style="vertical-align: center"
                             src="../assets/serivice/icon_6.png">
                        <span>Consultation</span>
                    </div>
                    <div class="header-home-list-child">
                        <span @click.stop="handleChildMenu(item, {path:'/serivices/consultation'})">Provide consultation on home inspections or construction work</span>
                    </div>
                </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <commentDialog :show.sync="show" />
  </div>
</template>

<script>
import commentDialog from "./component/commentDialog.vue"
export default {
  name: "",
  components: {
    commentDialog
  },
  data() {
    return {
      show: false,
      abc: "",
      menuList: [
        {
          id: "1",
          names: 'home',
          name: "Home",
          path: "/",
        },
        {
          id: "2",
          names: 'index',
          name: "About Us",
          path: "/index",
        },
        {
          id: "3",
          names: "services",
          name: "Home Delivery Service <br/> & Inspection",
          icon: true,
          IschildShow: false,
          path: "/serivices",
          child: [
            {
              id: "1",
              imgSrc: require("../assets/serivice/icon_1.png"),
              content: "Hood Inspection",
              desc: "range hood, ventilation fans and cleanliness",
              path: "/serivices/hood",
            },
            {
              id: "2",
              imgSrc: require("../assets/serivice/icon_2.png"),
              content: "Door and Window Inspection",
              desc: "automatic door system and digital door lock",
              path: "/serivices/door",
            },
            {
              id: "3",
              imgSrc: require("../assets/serivice/icon_3.png"),
              content: "Electrical Inspection",
              desc: "electrical inspection interior and exterior",
              path: "/serivices/electrical",
            },
            {
              id: "4",
              imgSrc: require("../assets/serivice/icon_4.png"),
              content: "Structural Inspection",
              desc: "foundation, structure and inside wall inspection ",
              path: "/serivices/structural",
            },
            {
              id: "5",
              imgSrc: require("../assets/serivice/icon_4.png"),
              content: "Internet and CCTV Inspection",
              desc: "internet system and CCTV system",
              path: "/serivices/internet",
            },
            {
              id: "6",
              imgSrc: require("../assets/serivice/icon_6.png"),
              content: "Roof Inspection",
              desc: "roof and rain gutters",
              path: "/serivices/roof",
            },
            {
              id: "7",
              imgSrc: require("../assets/serivice/icon_7.png"),
              content: "Plumbing Inspection",
              desc:
                "water meters, water storage tanks, water pumps, sanitary ware, wastewater treatment tanks, grease traps, and drainage systems",
              path: "/serivices/plumbing",
            },
            {
              id: "8",
              imgSrc: require("../assets/serivice/icon_8.png"),
              content: "Air conditioning Inspection",
              desc: "foundation, structure and inside wall inspection ",
              path: "/serivices/air",
            },
            {
              id: "9",
              imgSrc: require("../assets/serivice/icon_9.png"),
              content: "Stair Inspection",
              desc: "stair for public area",
              path: "/serivices/stair",
            },
          ],
        },
        {
          id: "4",
          names: "portfolio",
          name: "Portfolio",
          path: "/portfolio",
        },
        {
          id: "5",
          names: "reviews",
          name: "Reviews",
          path: "/reviews",
        },
        {
          id: "6",
          names: "contact",
          name: "Contact Us",
          path: "/contact",
        },
      ],
    };
  },
  mounted() {
    const _this = this;
    document.addEventListener('click', function () {
      _this.menuList[1].IschildShow = false
    }, false)
    
  },
  methods: {
    handleMouseLeave(item){
      if(item.id == 3){
        item.IschildShow = false;
      }
    },
    routerShow(item){
      if (item.id != 3) {
        item.IschildShow = false;
        // this.$router.push(
        //   item.path,
        //   () => { },
        //   () => { }
        // );
      } else {
        item.IschildShow = true;
      }
    },
    handleMenu(item) {
      if (item.id != 3) {
        item.IschildShow = false;
        this.$router.push(
          item.path,
          () => { },
          () => { }
        );
      } else {
        item.IschildShow = true;
      }
    },
    handleLogo() {
      this.$router.push('/')
    },
    handleShowComment() {
      this.show = true
    },

    handleChildMenu(item, child) {
      item.IschildShow = false
      this.$router.push(child.path, () => { }, () => { });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 10px 166px 10px 166px;
  box-sizing: border-box;
  justify-content: space-between;
}

.header-top {
  display: flex;
  width: 100%;
  height: 1080px;
  justify-content: space-between;
}

.logo {
  cursor: pointer;
  width: 70px;
  height: 50px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.menuBox {
  display: flex;
  width: 80%;
  justify-content: space-between;
  color: #333333;
  height: 50px;
  align-items: center;
  font-size: 16px;
}

.menuBox .menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.childmenu {
  width: 738px;
  height: 460px;
  position: absolute;
  top: 20px;
  left: -300px;
  z-index: 9999;
}

.bookBtn {
  width: 200px;
  height: 30px;
  background: #007562;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.header-bottom .frist {
  font-size: 30px;
  text-align: center;
  color: #fff;
}

.header-bottom .second {
  font-size: 16px;
  text-align: center;
  color: #fff;
  opacity: 0.73;
  margin-top: 28px;
}

.active {
  color: #007562;
  border-bottom: 2px solid #007562;
}

.s-our-serive-content {
  height: 430px;
  background: #f3f8f8;
  border-radius: 10px;
  border: 1px solid #007562;
  margin-bottom: 50px;
  padding: 52px 2px 50px 28px;
  box-sizing: border-box;
}

.s-our-serive-content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.s-our-serive-content-item {
  width: 48%;
  display: flex;
  margin-bottom: 20px;
  /* margin-bottom: 101px; */
}

.s-our-serive-content-item-img {
  width: 22px;
  height: 22px;
  background: #007562;
  margin-right: 10px;
  margin-top: 7px;
}

.s-our-serive-content-item-img img {
  width: 100%;
  height: 100%;
}

.s-our-serive-content-item-content {
  font-size: 16px;
  color: #000000;
}

.s-our-serive-content-item-desc {
  font-size: 12px;
  color: #333333;
  margin-top: 2px;
}

.header-home-list-child {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.header-home-list-child span{
    cursor: pointer;
    color: #999999;
    margin-right: 30px;
}

.header-home-list-child span:hover {
    color: #007562;
}
</style>
