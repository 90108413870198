<template>
  <div class="serivices">
    <headerTop />
    <breadcrumb :breadList="breadList" />
      <div class="c-contact-box">
          <div class="c-contact-text">
              <div class="title" style="padding-left: 40px;">
                  <p class="p1 font" style="color: #007562;">Samui Home Service</p>
                  <!-- <p class="p2">gain good Home service</p> -->
              </div>

              <ul class="c-contact-message">
                  <li class="c-contact-message-item">
                      <p><img src="../../assets/index/icon_phone.png" alt="" /></p>
                      <div class="c-contact-message-address">
                          <p class="c-contact-message-frist font">Mobile phone</p>
                          <p class="c-contact-message-second">+66 0935748046</p>
                      </div>
                  </li>
                  <li class="c-contact-message-item">
                      <p><img src="../../assets/index/icon_email.png" alt="" /></p>
                      <div class="c-contact-message-address">
                          <p class="c-contact-message-frist font">Email</p>
                          <p class="c-contact-message-second">steven@samuihomeservice.com</p>
                      </div>
                  </li>

                  <li class="c-contact-message-item">
                      <p><img src="../../assets/index/icon_facebook.png" alt="" /></p>
                      <div class="c-contact-message-address">
                          <p class="c-contact-message-frist font">Facebook</p>
                          <p class="c-contact-message-second">@SamuiHomeService</p>
                      </div>
                  </li>
                  <li class="c-contact-message-item-boom">
                      <p><img src="../../assets/index/icon_address.png" alt="" /></p>
                      <div class="c-contact-message-address">
                          <p class="c-contact-message-frist font">Address</p>
                          <p class="c-contact-message-second">Samui Town Center, 108/13 Moo 1, Bophut, Samui, Amphoe Koh SaMui,
                              Surat Thani, Thailand 84320.</p>
                      </div>
                  </li>
              </ul>
          </div>
          <div class="c-contact-map">
              <!-- <img class="c-contact-map-img" src="../../assets/index/map.jpg" alt=""> -->
              <GmapMap :center="center" :zoom="20" map-type-id="terrain" style="width: 100%; height: 100%">
                  <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
                              :draggable="true" @click="center = m.position" />
              </GmapMap>
          </div>
      </div>

    <footerBottom />
  </div>
</template>
<script>
import headerTop from "../../components/header.vue";
import breadcrumb from "../../components/breadcrumb.vue";
import footerBottom from "../../components/footer.vue";
export default {
  components: {
    headerTop,
    breadcrumb,
    footerBottom,
  },
  data() {
    return {
      breadList: ["Home", "Contact Us"],
        center: { lat: 9.55225733782695, lng: 100.03454199098668, },
        markers: [
            { position: { lat: 9.55225733782695, lng: 100.03454199098668 } }
        ],
      imgsArr: [
        {
          src: require("../../assets/new/icon_1.jpg"),
          href: "https://www.baidu.com",
          info: "我是第一张图片",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_2.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_3.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src:require("../../assets/new/icon_4.jpg"),
          href: "https://www.baidu.com",
          info: "我是第一张图片",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_5.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src:require("../../assets/new/icon_6.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_7.jpg"),
          href: "https://www.baidu.com",
          info: "我是第一张图片",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_8.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_9.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_10.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_11.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_12.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_13.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_14.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_15.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
      ],
      imgsArr_c: [], // 渲染的图片
      imgCol: 6, // 图片列数
      imgGap: 5, // 图片间间隔
      loadedCount: 0,
      imgBoxEls: [], // 所有 img-box 元素
      beginIndex: 0,
      colsHeightArr: [], // 保存当前每一列的高度
      reachBottomDistance: 20, // 滚动触底距离，触发加载新图片
      viewHeight: 0, // 窗口视图大小
       list: [
        {
          id: "1",
          imgSrc: require("../../assets/serivice/icon_1.png"),
          content: "Hood Inspection",
          desc: "range hood, ventilation fans and cleanliness",
          name: 'hood'
        },
        {
          id: "2",
          imgSrc: require("../../assets/serivice/icon_2.png"),
          content: "Door and Window Inspection",
          desc: "automatic door system and digital door lock",
          name: 'door'
        },
        {
          id: "3",
          imgSrc: require("../../assets/serivice/icon_3.png"),
          content: "Electrical Inspection",
          desc: "electrical inspection interior and exterior",
          name: 'electrical'
        },
        {
          id: "4",
          imgSrc: require("../../assets/serivice/icon_4.png"),
          content: "Structural Inspection",
          desc: "foundation, structure and inside wall inspection ",
          name: 'structural'
        },
        {
          id: "5",
          imgSrc: require("../../assets/serivice/icon_4.png"),
          content: "Internet and CCTV Inspection",
          desc: "internet system and CCTV system",
          name: 'internet'
        },
        {
          id: "6",
          imgSrc: require("../../assets/serivice/icon_6.png"),
          content: "Roof Inspection",
          desc: "roof and rain gutters",
          name: 'roof'
        },
        {
          id: "7",
          imgSrc: require("../../assets/serivice/icon_7.png"),
          content: "Plumbing Inspection",
          desc:
            "water meters, water storage tanks, water pumps, sanitary ware, wastewater treatment tanks, grease traps, and drainage systems",
          name: 'plumbing'
        },
        {
          id: "8",
          imgSrc: require("../../assets/serivice/icon_8.png"),
          content: "Air conditioning Inspection",
          desc: "foundation, structure and inside wall inspection ",
          name:'air'
        },
        {
          id: "9",
          imgSrc: require("../../assets/serivice/icon_9.png"),
          content: "Stair Inspection",
          desc: "stair for public area",
          name:'stair'
        },
      ],
    };
  },
  computed: {
    // 图片宽度
    imgWidth() {
      return this.colWidth - 2 * this.imgGap;
    },
    // 列宽度
    colWidth() {
      return this.waterfallWidth / this.colNum;
    },
    // 列数
    colNum() {
      return this.imgCol;
    },
  },
  watch: {
    imgsArr(newVal, oldVal) {
      if (
        this.imgsArr_c.length > newVal.length ||
        (this.imgsArr_c.left > 0 && newVal[0] && !newVal[0]._height)
      )
        this.reset();
      this.preLoad();
    },
  },
  methods: {
    // 预加载 设置图片宽高
    preLoad() {
      // forEach 无法通过 item 直接修改数组元素，需用数组下标修改
      this.imgsArr.forEach((item, index) => {
        if (index < this.loadedCount) return;
        if (!item.src) {
          this.imgsArr[index]._height = "0";
          ++this.loadedCount;
          if (this.imgsArr.length === this.loadedCount) {
            this.preloaded();
          }
        } else {
          let img = new Image();
          img.src = item.src;
          img.onload = img.onerror = (e) => {
            // 若加载失败则设置图片高度与宽度一致，加载成功则动态计算图片高度
            this.imgsArr[index]._height =
              e.type === "load"
                ? Math.round(this.imgWidth * (img.height / img.width))
                : this.imgWidth;
            if (e.type === "error") {
              this.imgsArr[index]._error = true;
            }
            ++this.loadedCount;
            if (this.imgsArr.length === this.loadedCount) {
              this.preloaded();
            }
          };
        }
      });
    },
    preloaded() {
      this.imgsArr_c = [].concat(this.imgsArr);
      this.$nextTick(() => {
        this.waterfall();
      });
    },
    // waterfall 布局
    waterfall() {
      // 等到整个视图都渲染完毕再执行
      this.imgBoxEls = this.$refs["imgBox"];
      if (!this.imgBoxEls) return;
      let top, left, height;
      if (this.beginIndex === 0) this.colsHeightArr = [];
      for (let i = this.beginIndex; i < this.imgBoxEls.length; ++i) {
        if (!this.imgBoxEls[i]) return;
        height = this.imgBoxEls[i].offsetHeight;
        // 第一行
        if (i < this.colNum) {
          this.colsHeightArr.push(height);
          top = 0;
          left = i * this.colWidth;
        } else {
          // 找到最低的高度和其索引
          let minHeight = Math.min.apply(null, this.colsHeightArr);
          let minIdx = this.colsHeightArr.indexOf(minHeight);
          top = minHeight;
          left = minIdx * this.colWidth;
          this.colsHeightArr[minIdx] += height;
        }
        // 设置 img-box 位置
        this.imgBoxEls[i].style.top = top + "px";
        this.imgBoxEls[i].style.left = left + "px";
        // 当前图片在窗口内，则加载
        if (top < this.viewHeight) {
          let imgEl = this.imgBoxEls[i].children[0];
          imgEl.src = imgEl.getAttribute("data-src");
          imgEl.style.opacity = 1;
          imgEl.style.transform = "scale(1)";
        }
      }
      this.beginIndex = this.imgBoxEls.length;
    },
    reset() {
      this.imgsArr_c = [];
      this.beginIndex = 0;
      this.loadedCount = 0;
    },
    // 滚动触底事件
    scrollFn() {
      let minHeight = Math.min.apply(null, this.colsHeightArr);
      // 滚动条滚动的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 到达最底层的高度最低的一列，则触发 handleReachBottom 方法
      if (scrollTop + this.viewHeight > minHeight - this.reachBottomDistance) {
        this.handleReachBottom();
      }
      // 图片懒加载
      this.imgBoxEls.forEach((imgBoxEl, index) => {
        let imgEl = imgBoxEl.children[0];
        // 若已加载，则跳过
        if (imgEl.src) return;
        // 当前图片所处的高度
        let top = imgBoxEl.style.top;
        top = Number.parseFloat(top.slice(0, top.length - 2));
        // 图片已到达可视范围，则加载
        if (scrollTop + this.viewHeight > top) {
          imgEl.src = imgEl.getAttribute("data-src");
          imgEl.style.opacity = 1;
          imgEl.style.transform = "scale(1)";
        }
      });
    },
    scroll() {
      window.onscroll = this.throttle(this.scrollFn, 500);
    },
    handleReachBottom() {
      this.imgsArr = this.imgsArr.concat(this.imgsArr);
      console.log("--this.imgsArr--", this.imgsArr);
    },
    // 节流函数
    throttle(fn, time) {
      let canRun = true;
      return function () {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this);
          canRun = true;
        }, time);
      };
    },
  },
  mounted() {
    this.viewHeight =
      document.documentElement.clientHeight == 0
        ? document.body.clientHeight
        : document.documentElement.clientHeight;
    this.preLoad();
    // this.scroll();
  },
};
</script>
<style scoped>
.serivices {
  background: #fff;
  padding-top: 10px;
  box-sizing: border-box;
}

.s-content {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
}

.s-img-box {
  width: 51%;
}

.gradient-button {
  width: 197px;
  height: 39px;
  border: none;
  color: white;
  /* text-align: center; */
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  line-height: 39px;
  margin-bottom: 20px;

  /* 为按钮添加线性渐变背景 */
  background: linear-gradient(90deg, #007562 0%, rgba(0, 117, 98, 0) 100%);
}

.s-content-desc img {
  margin-right: 10px;
}

.s-content-desc {
  font-size: 16px !important;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  align-items: first baseline;
}
.s-content-desc img {
  margin-right: 10px;
}

.s-content-box p {
  font-size: 18px;
  /* line-height: 32px; */
  display: flex;
  align-items: flex-start;
  font-family: inherit;
  /* 继承父元素的字体 */
  font-size: inherit;
  /* 继承父元素的字体大小 */
  line-height: inherit;
  /* 继承父元素的行高 */
  margin: 30px 0;
}

.s-content-box {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.s-book-btn {
  border: none;
  color: white;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  background: #007562;
  width: 200px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
}

.s-waterfall-title {
  width: 90%;
  margin: 0 auto;
  font-size: 30px;
  color: #007562;
  margin-top: 50px;
  margin-bottom: 55px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.waterfall {
  width: 90%;
  position: relative;
  margin: 0 auto;
  height: 600px;
  overflow-y: scroll;
}
/* .s-content-box p{
  font-size: 18px;
  line-height: 32px;
} */
@keyframes show-card {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.img-box {
  position: absolute;
  border-radius: 10px;
  padding: 5px;
  padding-left: 0;
}

img {
  width: 100%;
  border-radius: 10px;
  /* opacity: 0; */
  /* transform: scale(0.5); */
  transition: all 0.6s;
  transition-delay: 0.1s;
}

.s-our-serive {
  width: 90%;
  margin: 0 auto;
}

.s-our-serive-title {
  margin: 50px 0;
  font-size: 30px;
  color: #007562;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.s-our-serive-content {
  height: 360px;
  background: #f3f8f8;
  border-radius: 10px;
  border: 1px solid #007562;
  margin-bottom: 50px;
  padding: 52px 28px 50px 28px;
  box-sizing: border-box;
}

.s-our-serive-content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.s-our-serive-content-item {
  width: 32%;
  display: flex;
  margin-bottom: 40px;
  cursor: pointer;
}

.s-our-serive-content-item-img {
  width: 48px;
  height: 48px;
  background: #007562;
  margin-right: 22px;
}

.s-our-serive-content-item-img img {
  width: 100%;
  height: 100%;
}

.s-our-serive-content-item-content {
  cursor: pointer;
  font-size: 16px;
  color: #000000;
}

.s-our-serive-content-item-desc {
  cursor: pointer;
  font-size: 12px;
  color: #333333;
  margin-top: 11px;
}
.img-box img{
  height: 100%;
  width: 100%;
}
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.font{
  font-family: 'MyCustomFont', sans-serif;
}


.c-contact-box {
    padding: 30px 166px 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px
}

.c-contact-text {
    width: 43%;
    height: 700px;
    background: #f5f5f5;
}

.c-contact-text .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    text-align: center;
    background: #eaf4f2;

}

.c-contact-text .p1 {
    width: 100%;
    font-size: 24px;
    color: #333333;
    text-align: left;
    font-weight: 800;
}

.c-contact-text .p2 {
    width: 100%;
    font-size: 16px;
    color: #666666;
    text-align: left;
}

/deep/.gmnoprint {
    display: none !important;
}

.c-contact-message {
    padding: 0 48px;
    box-sizing: border-box;
    margin: 30px 0;
}

/deep/.ytp-impression-link {
    display: none !important;
}

.c-contact-message-item {
    display: flex;
    border-bottom: 1px solid #979797;
    padding-bottom: 34px;
    box-sizing: border-box;
    margin-bottom: 33px;
}
.c-contact-message-item-boom {
    display: flex;
    padding-bottom: 34px;
    box-sizing: border-box;
    margin-bottom: 33px;
}

.c-contact-message-item-last {
    display: flex;

    padding-bottom: 34px;
    box-sizing: border-box;
    margin-bottom: 33px;
}

.c-contact-message-item-last img {
    width: 40px;
    height: 40px;
}
.c-contact-message-item-boom img{
    width: 40px;
    height: 40px;
}

.c-contact-message-item img {
    width: 40px;
    height: 40px;
}

.c-contact-message-address {
    margin-left: 30px;
}

.c-contact-message-frist {
    font-weight: 800;
    font-size: 18px;
    color: #333333;
}

.c-contact-message-second {
    font-size: 16px;
    color: #666666;
    margin-top: 6px;
}

.c-contact-map {
    width: 55%;
}

.c-contact-map img {
    width: 100%;
    height: 700px;
}
</style>
