<template>
  <div>
    <headerTop />
    <breadcrumb :breadList="breadList" />
    <div class="c-content">

      <p class="s-waterfall-title font" style="display: flex; flex-direction: row;margin-right: 20px;"><span
          style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block; "></span>Filter
        by</p>
      <div class="filter-List">
        <ul class="filter-list">
          <li class="filter-item" :class="num == index ? 'active' : ''" v-for="(item, index) in list" :key="index"
            @click="handlefilter(index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <ul  class="insights-box-list">
        <li @click="routerItem(item)" v-for="(item, index) in news" :key="index">
          <p class="box-list-label">{{ item.label }}</p>
          <p class="insights-img-box"><img :src="item.coverUrl" alt=""></p>
          <p class="insights-desc">{{ item.title }}</p>
          <p class="insights-more">Read More</p>
        </li>
      </ul>

    </div>
    <footerBottom />
  </div>
</template>
<script>
import headerTop from "../../components/header.vue";
import breadcrumb from "../../components/breadcrumb.vue";
import footerBottom from "../../components/footer.vue";
import { getNewsList } from "@/api/contact";
export default {
  components: {
    headerTop,
    breadcrumb,
    footerBottom,
  },
  data() {
    return {
      breadList: ["Home", "News"],
      list: ['ALL'],
      num: 0,
      news: [
      ],
      show: false,
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    handlefilter(i) {
      this.num = i
    },
    lisk() {
      console.log('123123');
    },
    routerItem(item) {
      this.$router.push({ name: 'newDetail', params: item })
      console.log('111222333');
    },
    getList() {
      getNewsList(1, 1000).then(res => {
        if (res.code === 200) {
          this.news = res.rows;
        }
      })
    },
  },
};
</script>
<style scoped>
.c-content {
  padding: 0 166px;
  box-sizing: border-box;
}

.new-title {
  color: #007562;
  font-size: 30px;
  margin-top: 27px;
  margin-bottom: 28px;
}

.filter-list {
  display: flex;
}

.filter-item {
  text-align: center;
  border: 1px solid #ccc;
  height: 50px;
  border-radius: 26px;
  line-height: 50px;
  cursor: pointer;
  margin-right: 20px;
  padding: 0 26px;
  box-sizing: border-box;
  /* font-size: 14px; */
}

.active {
  background: #007562;
  color: #fff;
}

.insights-box-list {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  flex-wrap: wrap;
  z-index: -1;

}

.insights-box-list li {
  position: relative;
  width: 32.5%;
  height: 464px !important;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  z-index: 999;
}

.box-list-label {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #FFFFFF;
  padding: 4px;
  border-radius: 3px;
  z-index: 9999;
}

.insights-img-box {

  height: 302px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.insights-img-box img {
  width: 100%;
  height: 100%
}

.insights-desc {
  margin-top: 17px;
  margin-left: 20px;
  font-size: 16px;
  height: 50px
}

.insights-more {
  margin-top: 25px;
  margin-left: 20px;
  font-size: 16px;
  color: #007562;
  text-decoration: underline;
  cursor: pointer;
}

.s-waterfall-title {
  width: 90%;
  font-size: 30px;
  color: #007562;
  margin-top: 50px;
  margin-bottom: 55px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font {
  font-family: 'MyCustomFont', sans-serif;
}
</style>
