<template>
  <div class="footer">
    <div>
      <div class="contact-box">
        <div style="display: flex; flex-direction: column;">
          <p class="contact-box-desc">Stay Connected With </p>
          <p class="contact-box-desc">Samui Home Service</p>
        </div>

        <div class="contact-box-schedule-box" @click="handleShowComment()">
          <span class="contact-box-schedule">Schedule Inspection <br>Click Here To
            Request</span>
          <!-- <div class="contact-box-phone">
            <span class="phoneNumber">+66 0935748046</span><span>Call Now & Request</span>
          </div> -->
        </div>
      </div>
      <p class="line"></p>
      <div class="footer-nav">
        <p><img src="../assets/home/logo1.png" alt="" /></p>
        <ul class="footer-menu-list" style="margin-left: 100px;">
          <!-- <li on-click="">Home</li> -->
          <router-link class="routerLink" :to="{ name: 'home' }">Home</router-link>
          <!-- <li>Services</li> -->
          <!-- <router-link class="routerLink" :to="{ name: 'services' }">Services</router-link> -->
          <!-- <li>Reviews</li> -->
          <router-link class="routerLink" :to="'serivices/hood'">Home Delivery Service <br/> & Inspection</router-link>
          <router-link class="routerLink" :to="{ name: 'reviews' }">Reviews</router-link>
          <!-- <li>Who We Are</li> -->

        </ul>
        <ul class="footer-menu-list">
          <router-link class="routerLink" :to="{ name: 'index' }">About Us</router-link>
          <!-- <li>Home</li> -->
          <!-- <router-link class="routerLink" :to="{ name: 'home' }">Home</router-link> -->
          <!-- <li>Portfolio</li> -->
          <router-link class="routerLink" :to="{ name: 'portfolio' }">Portfolio</router-link>
          <router-link class="routerLink" :to="{ name: 'contact' }">Contact Us</router-link>
        </ul>
        <div class="footer-icon-list">
          <p class="footer-address">
            <img src="../assets/home/address.png" alt="" /><span><a
                href="https://www.google.com/maps?q=9.55225733782695,100.03454199098668" target="_blank">Samui Town
                Center, 108/13 Moo 1, Bophut, Samui, Amphoe Koh SaMui, Surat Thani, Thailand 84320.</a></span>
          </p>
          <p class="footer-email">
            <img src="../assets/home/email.png" alt="" /><span><a
                href="mailto:steven@samuihomeservice.com">steven@samuihomeservice.com</a></span>
          </p>
          <p class="footer-phone">
            <img src="../assets/home/phone.png" alt="" /><span>+66 0935748046</span>
          </p>
        </div>
      </div>
      <p class="secondline"></p>
      <p class="label">@2024 Samui Home Service. All Right Reserved</p>
    </div>
    <commentDialog :show.sync="show" />
  </div>

</template>

<script>
import commentDialog from "./component/commentDialog.vue"
export default {
  name: "FooterBottom",
  components: {
    commentDialog

  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    handleShowComment() {
      console.log('1111');
      this.show = true
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  /* 去掉下划线 */
  color: inherit;
  /* 继承父元素的颜色 */
}

.footer {
  width: 100%;
  height: 350px;
  background: #007562;
  padding: 20px 166px 37px;
  box-sizing: border-box;
}

.contact-box {
  display: flex;
  justify-content: space-between;
}

.contact-box-schedule-box {
  display: flex;
  padding-right: 70px;
  box-sizing: border-box;
}

.contact-box-desc {
  width: 250px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}

.contact-box-schedule {
  display: inline-block;
  width: 220px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  color: #fff;
  font-size: 16px;
  /* padding: 13px 5px; */
  /* box-sizing: border-box; */
  text-align: center;
  line-height: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.contact-box-schedule:hover {
  background-color: #00927A;
}

.contact-box-phone {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
  width: 190px;
  height: 60px;
  border-radius: 10px;
  color: #fff;
  line-height: 30px;
  font-size: 16px;
  box-sizing: border-box;
  text-align: center;
}

.contact-box-phone:hover {
  background-color: #00927A;
}

.phoneNumber {
  font-size: 16px;
  line-height: 30px;
}

.line {
  width: 100%;
  height: 1px;
  background: #00927a;
  margin: 20px 0 20px;
}

.footer-nav {
  display: flex;
  justify-content: space-between;
}

.footer-nav img {
  width: 140px;
  height: 100px;
}

.footer-menu-list {
  width: 25%;
  height: 160px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.footer-menu-list .routerLink {
  color: #fff;
  font-size: 16px;
  margin-top: 20px
}

.routerLink:hover {
  font-weight: 800;
}

.footer-icon-list {
  width: 30%;
}

.footer-address {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footer-address span {
  width: 400px;
}

.footer-email {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footer-phone {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  /* margin-bottom: px; */
}

.footer-address img,
.footer-email img,
.footer-phone img {
  margin-right: 20px;
  width: 30px;
  height: 30px;
}

.secondline {
  border: 1px solid #00927A;
  margin-top: 20px;
}

.label {
  text-align: center;
  font-size: 14px;
  margin-top: 18px;
  color: #FFFFFF;
}
</style>
