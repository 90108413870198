import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home'
import Index from '../views/Index'
import Certicication from '../views/Certicication'
import Reviews from '../views/reviews'
import Portfolio from '../views/portfolio'
import hood from '../views/serivices/hood.vue'
import door from '../views/serivices/door.vue'
import electrical from '../views/serivices/electrical.vue'
import structural from '../views/serivices/structural.vue'
import internet from '../views/serivices/internet.vue'
import roof from '../views/serivices/roof.vue'
import plumbing from '../views/serivices/plumbing.vue'
import air from '../views/serivices/air.vue'
import stair from '../views/serivices/stair.vue'
import New from '../views/new'
import NewDetail from '../views/new/newdetail'
import architectural from "@/views/serivices/architectural.vue";
import landscaping from "@/views/serivices/landscaping.vue";
import homeAppliances from "@/views/serivices/homeAppliances.vue";
import swimming from "@/views/serivices/swimming.vue";
import consultation from "@/views/serivices/consultation.vue";
import contact from "@/views/contact/index.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/certicication',
    name: 'certicication',
    component: Certicication
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: Reviews
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: Portfolio
  },
  {
    path: '/serivices/hood',
    name: 'serivices',
    component: hood
  },
  {
    path: '/serivices/door',
    name: 'serivices',
    component: door
  },
  {
    path: '/serivices/electrical',
    name: 'serivices',
    component: electrical
  },
  {
    path: '/serivices/structural',
    name: 'serivices',
    component: structural
  },
  {
    path: '/serivices/architectural',
    name: 'serivices',
    component: architectural
  },
  {
    path: '/serivices/landscaping',
    name: 'serivices',
    component: landscaping
  },
  {
    path: '/serivices/homeAppliances',
    name: 'serivices',
    component: homeAppliances
  },
  {
    path: '/serivices/swimming',
    name: 'serivices',
    component: swimming
  },
  {
    path: '/serivices/consultation',
    name: 'serivices',
    component: consultation
  },
  {
    path: '/serivices/internet',
    name: 'serivices',
    component: internet
  },
  {
    path: '/serivices/roof',
    name: 'serivices',
    component: roof
  },
  {
    path: '/serivices/plumbing',
    name: 'serivices',
    component: plumbing
  },
  {
    path: '/serivices/air',
    name: 'serivices',
    component: air
  },
  {
    path: '/serivices/stair',
    name: 'serivices',
    component: stair
  },
  {
    path: '/new',
    name: 'new',
    component: New
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    component: NewDetail
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
